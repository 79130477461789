<script setup lang="ts">


const props = defineProps({
  username: String,
  email: String,
  photo: String,
  role: String
});
</script>

<template>
  <div class="profile-logo-card flex justify-between items-center ">
    <div class="flex gap-2">
      <CustomUiUserAvatar :name="props.username" :src="props.photo" size="2xs" />
      <div class="">
        <p class="text-name">{{ props.username }}</p>
        <p class="text-email">{{ props.email }}</p>
      </div>
    </div>
    <Icon size="15" class="ltr:mr-1 rtl:ml-1 rtl:rotate-180" name="ri:arrow-right-s-line"/>
  </div>
</template>

<style scoped lang="scss">

.profile-logo-card {
  background: rgb(255, 255, 255);
  width: 248px;
  height: 64px;
  padding: 12px;
  gap: 12px;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background: rgba(246, 248, 250, 1);
  }
  &:active {
    background: rgba(246, 248, 250, 1);
  }
  @media(max-width:1024px){
    width: 100%;
    padding: 0  0 0;
  }
}

.text-name {
  color: rgba(10, 13, 20, 1);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.text-email {
  color: rgba(82, 88, 102, 1);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.ys-avatar-style {
  width: 40px;
  height: 40px;
  border-radius: 96px;
}

</style>